import useConfigEnv from '@/composables/useConfigEnv'
import { computed, ref, useAsyncData, useRoute, useRouter, navigateTo, useNuxtApp, useI18n } from '#imports'
import { useSearchStore } from '../stores/search.store'
import type { HaMultipleQueriesQueryUI } from '../interfaces/query.interface'
import { SEARCH_ALL_INDEX, type HASearchResponse, SEARCH_INDEX } from '../interfaces/search.interface'
import { mapResultsByKeys, paramsUrlToQuery, tabToIndexes, createQueries, convertSearchParamsToString, queryToLocationQuery, convertSearchParamValuesToString } from '../helpers/search.helper'
import { TABS } from '../interfaces/filters.interface'
import type { LocationQuery } from 'vue-router'
import { functions } from '@ha/helpers'
import useDebug from '~/domains/app/composables/debug.composable'

export default function useSearch() {
  const store = useSearchStore()
  const router = useRouter()
  const route = useRoute()
  const i18n = useI18n()
  const debug = useDebug()
  const configEnv = useConfigEnv()
  const { $algoliaClient } = useNuxtApp()

  const isLoading = ref(false)
  const isMapDisplayed = computed({
    get: () => store.isMapDisplayed,
    set: (value) => store.setIsMapDisplayed(value)
  })

  const currentTab = computed(() => route.params.search_tab as TABS || TABS.ALL)
  const currentTabIndexes = computed(() => tabToIndexes[currentTab.value] as SEARCH_ALL_INDEX[])
  const currentQuery = computed(() => store.getQuery(currentTabIndexes.value[0]))
  const currentResult = computed(() => store.getResult(currentTabIndexes.value[0]))
  const currentUrlQuery = computed(() => paramsUrlToQuery(route.query))
  const resultTotalCount = computed(() => {
    let resultTotalCount = 0
    for (const key in store.results) {
      if (Object.hasOwn(store.results, key)) {
        const result = store.results[key]
        resultTotalCount += result?.nbHits
      }
    }
    return resultTotalCount
  })

  const storeUrlQuery = () => {
    // set search from url  to store
    store.setSearch(currentUrlQuery.value.query)
    // set filters from url  to store
    store.setFilters(currentUrlQuery.value.params.filters)
    // set params from url  to store
    store.setParams(currentUrlQuery.value.params)
  }

  /**
   * Fetch queries state and store results
   * @returns
   */
  const fetchAll = async () => {
    const results = await fetch(store.queries)
    store.setResults(results)
    return results
  }

  const fetchAllSSR = (ssrKey: string) => useAsyncData(ssrKey, () => fetchAll())

  const fetchByKeys = (keys: string[]) => {
    const fetchQueries = {}
    for (const key of keys)
      fetchQueries[key] = store.queries[key]
    return fetch(fetchQueries)
  }

  const fetchByKey = (key: string) => {
    return fetch({ [key]: store.getQuery(key) })
  }

  const fetch = async (queries: Record<string, HaMultipleQueriesQueryUI>) => {
    const queriesValue = Object.values(queries)
    const queriesKeys = Object.keys(queries)

    try {
      if (isLoading.value === true) {
        debug.warn('[search.composable] Fetch already called')
      }
      isLoading.value = true

      // @ts-expect-error - Le typage du plugin ne semble pas pris en compte.
      const results = await $algoliaClient.instance.fetch(queriesValue)
      if (results?.length) {
        return mapResultsByKeys<HASearchResponse>(queriesKeys, results)
      } else {
        debug.warn('[search.composable] Result is empty', results)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw error
    } finally {
      isLoading.value = false
    }

  }

  const setStoreToUrlQuery = async () => {
    const queryAll = queryToLocationQuery({
      indexName: SEARCH_ALL_INDEX.ALL,
      query: store.search,
      params: {
        ...store.params,
        filters: store.filters
      }
    })
    await setParamsToUrlQuery(queryAll)
  }

  const setParamsToUrlQuery = async (
    params: LocationQuery
  ) => {
    const query = convertSearchParamValuesToString(params)
    const isSearchPage = window.location.href.includes('/e/recherche')
    const isWidgetPage = window.location.href.includes('/e/creer-mon-widget')
    const path = (isSearchPage || isWidgetPage) ? undefined : '/e/recherche'
    if (typeof route.query.map !== 'undefined') {
      query.map = null
    }

    try {
      router.replace({ path, query })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error.message);
    }

  }

  const createQueriesAndFetchSSR = async (indexes: SEARCH_ALL_INDEX[]) => {
    const queriesLocal = createQueries(
      indexes,
      store.search,
      store.params,
      store.filters,
    )

    store.setQueries(queriesLocal)

    try {
      await fetchAllSSR('algolia-search-ssr')
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw error
    }
  }

  const updateQueriesAndFetch = async (indexes: SEARCH_ALL_INDEX[]) => {
    const queriesLocal = createQueries(
      indexes,
      store.search,
      store.params,
      store.filters,
    )

    for (const index of indexes) {
      store.mergeQuery(index, queriesLocal[index])
    }

    try {
      await fetchAll()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw error
    }
  }

  const changeTab = async (newTab: TABS | string) => {
    // TODO - [refacto] add function to clean params
    delete route.query.page
    delete route.query.search_tab
    // Used to delete redirection=true in URL (Cf. GP-312).
    delete route.query.redirect

    const stripedNewTabs = functions.deburr(newTab)

    if (!stripedNewTabs || stripedNewTabs == TABS.ALL) {
      navigateTo(
        `/e/recherche?${convertSearchParamsToString(route.query)}`
      )
    } else {
      navigateTo(
        `/e/recherche/${stripedNewTabs}?${convertSearchParamsToString(route.query)}`
      )
    }
  }

  const navigateFromOutsideSearch = (params) => window.location.href = `${configEnv.NUXT_ENV_BASE_URL}/e/recherche` + params

  const translateIndexName = (index) => {
    if (index === SEARCH_INDEX.ACTIVITIES) {
      return i18n.t(`search.tabs.activites`)
    }
    if (index === SEARCH_INDEX.ORGANIZATIONS) {
      return i18n.t(`search.tabs.associations`)
    }
    if (index === SEARCH_INDEX.PROJECTS) {
      return i18n.t(`search.tabs.projets`)
    }
    return index
  }

  return {
    createQueriesAndFetchSSR,
    updateQueriesAndFetch,
    fetch,
    fetchByKey,
    fetchByKeys,
    fetchAllSSR,
    fetchAll,
    setParamsToUrlQuery,
    setStoreToUrlQuery,
    storeUrlQuery,
    changeTab,
    translateIndexName,
    navigateFromOutsideSearch,
    currentTab,
    currentQuery,
    currentUrlQuery,
    currentResult,
    currentTabIndexes,
    resultTotalCount,
    isLoading,
    isMapDisplayed,
    store
  }
}
