import { defineStore } from 'pinia'
import type { HaMultipleQueriesQueryUI } from '../interfaces/query.interface';
import type { HASearchResponse } from '../interfaces/search.interface';
import type { FiltersAll } from '../interfaces/filters.interface';
import type { ContentBlock } from '~/domains/thematic/interfaces/content.interface';

export interface StateAlgolia {
  queries: Record<string, HaMultipleQueriesQueryUI>
  results: Record<string, HASearchResponse>
  filters: FiltersAll
  search: string
  params: Omit<HaMultipleQueriesQueryUI['params'], 'filters'>
  isMapDisplayed: boolean
}

export const useSearchStore = defineStore('algolia', {
  state: (): StateAlgolia => ({
    queries: {},
    results: {},
    filters: {
      category_tags: []
    },
    params: {},
    search: '',
    isMapDisplayed: false
  }),

  getters: {
    getQuery: (state) => (key: string) => state.queries?.[key],
    getResult: (state) => (key: string) => state.results?.[key],
    getBlock: (state) => (key: string): ContentBlock => (
      {
        searchable: state.queries[key],
        result: state.results[key]
      }
    )
  },

  actions: {
    // Search
    setSearch(search: string) {
      this.search = search
    },

    // filters
    setFilter(key: string, value: any) {
      this.filters[key] = value
    },
    setFilters(filters: StateAlgolia['filters']) {
      this.filters = filters
    },
    mergeFilter(filters: StateAlgolia['filters']) {
      this.filters = { ...this.filters, ...filters }
    },

    // params
    setParam(key: string, value: any) {
      this.params[key] = value
    },
    setParams(params: StateAlgolia['params']) {
      this.params = params
    },
    mergeParam(params: any) {
      this.params = { ...this.params, ...params }
    },

    // results
    setResult(key: string, result: HASearchResponse) {
      this.results[key] = result
    },
    setResults(results: StateAlgolia['results']) {
      this.results = results
    },

    // Queries
    setQuery(key: string, query: HaMultipleQueriesQueryUI) {
      this.queries[key] = query
    },
    mergeQuery(key: string, query: HaMultipleQueriesQueryUI) {
      if (typeof this.queries[key] === 'undefined') {
        this.setQuery(key, query)
      } else {
        this.queries[key] = {
          ...this.queries[key],
          ...query,
          params: {
            ...this.queries[key].params,
            ...query.params,
            filters: {
              ...this.queries[key].params.filters,
              ...query.params.filters,
            }
          }
        }
      }
    },
    setQueries(queries: StateAlgolia['queries']) {
      this.queries = queries
    },

    setIsMapDisplayed(value:boolean) {
      this.isMapDisplayed = value
    }

  },

})